import styled, { css } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import Link from "../navigation/link.component"
import { percent } from "../structure/theme"

export const LegalTitle = styled.h2`
  text-align: ${props => props.textAlign};
  text-transform: uppercase;
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fonts.sizes.xx_large};
  text-decoration: ${props => props.textDecoration || `underline`};
  word-break: break-word;
  a {
    color: ${props => props.theme.colors.primary};
    font-size: ${props => props.theme.fonts.sizes.xx_large};
    text-decoration: underline;
  }
`

export const LastMaj = styled.div`
  font-style: italic;
  margin-bottom: 32px;
`

const subtitleCss = css`
  word-break: break-word;
  font-size: ${props => props.theme.fonts.sizes.large};
  font-weight: 900;
  color: ${props => props.theme.colors.primary};
`

export const LegalMinititle = styled.h4`
  ${subtitleCss};
`

export const LegalSubtitle = styled.h3`
  ${subtitleCss};
`

export const CookieSubtitle = styled.h2`
  ${subtitleCss};
`

export const LegalLink = styled(Link)`
  text-decoration: none;
`

export const Underline = styled.span`
  text-decoration: underline;
`

export const Italic = styled.span`
  font-style: italic;
`

export const ExternalLink = styled.a.attrs(() => ({ target: `_blank` }))`
  overflow-wrap: anywhere;
`

export const CookieLine = styled.div`
  display: flex;
  border-top: 1px solid ${props => props.theme.colors.grey.g500};
`

export const CookieLabel = styled.div`
  width: ${percent(1 / 2)};
  padding-left: 20px;

  ${breakpoint(`small`)`
    width: ${percent(2 / 3)};
  `}
`

export const CookieContent = styled.div`
  width: ${percent(1 / 2)};

  ${breakpoint(`small`)`
    width: ${percent(1 / 3)};
  `}
`
