import React from "react"
import Link from "../../navigation/link.component"
import { Bold } from "../../structure/theme/styles.component"
import { useViewPageTracking } from "../../thirdParties/gtm/tagManager"
import {
  LegalTitle,
  LegalSubtitle,
  LegalLink,
  LastMaj,
  ExternalLink,
  LegalMinititle,
  Underline,
  Italic,
} from "../legal.styled"
import { createSelfCareLink } from "../../navigation/navigation.utils"
import Block from "../../../components/block/block.component"

export default function ConditionsjuridiquesPage () {
  useViewPageTracking(`Juridique`, ``, `Conditions juridiques`)

  return (
    <Block
      blockTitle={
        <>
          <Bold>Conditions juridiques</Bold> du site{`\u0020`}
          {APP_CONFIG.legalSiteName.replace(`www.`, ``)}
        </>
      }
      blockTitleSeo="h1"
      data-testid="conditions_juridiques_title"
    >
      <LastMaj>Date de mise à jour : 10 février 2020</LastMaj>
      <p>
        Les présentes conditions juridiques du Site sont conclues entre HomeServe et un Client ou Utilisateur dans le
        cadre de l’utilisation du Site, de ses Services et notamment du Service de commande en ligne d’un Contrat
        d’Assurance (les{` `}
        <Bold fontWeight={700}>
          « <Bold>Conditions Juridiques</Bold> »
        </Bold>
        ).
      </p>
      <p>
        Toute utilisation du Site et des Services accessibles implique l’acceptation pleine et entière des présentes
        Conditions Juridiques.
      </p>
      <p>
        Les Conditions Juridiques sont composées des documents contractuels suivants, applicables en tout ou partie au
        profil de l’Utilisateur (et notamment selon qu’il soit simple visiteur ou Client) :
      </p>

      <ul>
        <li>
          Les Conditions Générales d’Utilisation et de Vente du Site (les «<Bold>CGU/CGV</Bold> ») ;
        </li>
        <li>La Politique de données personnelles</li>
        <li>La Politique des Cookies</li>
      </ul>

      <LegalTitle> Définitions </LegalTitle>

      <p>Les présentes définitions s’appliquent à l’ensemble des documents des Conditions Juridiques du Site.</p>

      <p>
        <Bold fontWeight={700}>« Client »</Bold> : désigne toute personne physique nécessairement majeure qui souscrit
        un Contrat d’Assurance sur le Site ou par tout autre moyen, pour ses besoins personnels, et ayant la capacité de
        conclure un tel acte.
      </p>
      <p>
        <Bold fontWeight={700}>« Contrat d’Assurance »</Bold> : désigne un contrat d’assistance et/ou d’assurance conçu,
        distribué et géré par HomeServe.
      </p>
      <p>
        <Bold fontWeight={700}>« Données Personnelles »</Bold> : désignent les données à caractère personnel au sens de
        la réglementation sur les données personnelles (soit notamment le Règlement Européen UE 2016/679 du 27 avril
        2016 ainsi que la loi n°78-17 du 6 janvier 1978 modifiée relative à l’informatique, aux fichiers et aux
        libertés) qui sont collectées et traitées par HomeServe à différentes occasions (visite du Site, utilisation des
        Services, Souscription d’un Contrat d’Assurance, etc.). Plus d’informations sur la Politique de Données
        Personnelles.
      </p>
      <p>
        <Bold fontWeight={700}>« Espace Client »</Bold> : désigne un espace personnel sécurisé, accessible à partir du
        Site et mis à la disposition de chaque Client au moyen d’un identifiant et d’un mot de passe confidentiel.
        L’Espace Client propose des services de gestion de son Contrat d’Assurance et de ses préférences de contact.
        L’Espace Client est édité par HomeServe, accessible à l’adresse{` `}
        <ExternalLink href={createSelfCareLink()}>espace-client/homeserve.fr</ExternalLink>
        {` `}
        et hébergé par la société HomeServe, SAS dont le siège social est sis 9 rue Anna Marly - CS 80510 - 69365 Lyon
        Cedex 07. S.A.S.
      </p>
      <p>
        <Bold fontWeight={700}>« HomeServe »</Bold> : désigne la société HOMESERVE, société par actions simplifiée au
        capital de 40 000 euros, immatriculée au RCS Lyon sous le numéro 438 424 384, société de courtage en assurance
        immatriculée sur le registre de l’ORIAS n°07023309 (
        <ExternalLink href="http://www.orias.fr">www.orias.fr</ExternalLink>) dont le siège social est situé au 9 rue
        Anna Marly – CS 80510 – 69365 Lyon Cedex 07.
      </p>
      <p>
        <Bold fontWeight={700}>« Services »</Bold> : désignent l’ensemble des services mis à la disposition des
        Utilisateurs et/ou des Clients par l’intermédiaire du Site et notamment décrits à l’article 2 des CGU/ CGV du
        Site.
      </p>
      <p>
        <Bold fontWeight={700}>« Site »</Bold> : désigne le site internet de HomeServe accessible à l’adresse{` `}
        <ExternalLink href={APP_CONFIG.legalSiteName}>{APP_CONFIG.legalSiteName.replace(`www.`, ``)}</ExternalLink>
        {` `}
        ainsi que l’Espace Client accessible à l’adresse{` `}
        <ExternalLink href={createSelfCareLink()}>espace-client/homeserve.fr</ExternalLink>
        {` `}.
      </p>
      <p>
        <Bold fontWeight={700}>« Groupe HomeServe »</Bold> : désigne HomeServe ainsi que ses sociétés sœurs : HomeServe
        On Demand (siège social : 9, rue Anna Marly 69007 Lyon, RCS Lyon n°838 756 237), HomeServe Energy Services
        (siège social : 9, rue Anna Marly 69007 Lyon, RCS Lyon n°532 763 455) et HomeServe Home Expert (siège social :
        9, rue Anna Marly 69007 Lyon, RCS Lyon 848 943 072).
      </p>
      <p>
        <Bold fontWeight={700}>« Souscription »</Bold> : désigne l’acceptation du Contrat d’Assurance par le Client et
        HomeServe sur le Site qui comprend une autorisation de paiement en ligne de la prime par prélèvement automatique
        ou par carte bancaire.
      </p>
      <p>
        <Bold fontWeight={700}>« Utilisateur »</Bold> : désigne toute personne visitant le Site et/ou utilisant les
        Services mis à disposition sur le Site.
      </p>
      <LegalTitle>I/ CONDITIONS GÉNÉRALES D’UTILISATION ET DE VENTE DU SITE</LegalTitle>
      <LegalSubtitle>Article 1 Objet</LegalSubtitle>
      <p>
        Les présentes CGU/CGV ont pour objet de déterminer les conditions d’utilisation du Site et des Services et
        notamment de vente de Contrats d’Assurance sur le Site. Tous les Utilisateurs s’engagent à respecter
        l’intégralité des présentes CGU/CGV.
      </p>
      <LegalSubtitle>Article 2 Services</LegalSubtitle>
      <p>Depuis le Site, les Utilisateurs ont notamment accès aux Services suivants :</p>
      <ul>
        <li>Souscription en ligne de Contrat d’Assurance ;</li>
        <li>Création d’un Espace Client ;</li>
        <li>Le rappel gratuit pour tout Utilisateur intéressé par les Contrats d’Assurance.</li>
      </ul>

      <LegalSubtitle>Article 3 - Service de Souscription en ligne de Contrat d’Assurance</LegalSubtitle>
      <p>
        Toute souscription de Contrats d'Assurance implique également l’adhésion aux conditions générales relatives au
        Contrat d'Assurance souscrit. En cas de contradiction, les conditions générales priment sur les présentes
        CGU/CGV. En cas de modification des présentes CGU/CGV, il sera appliqué pour chaque Souscription en ligne d'un
        Contrat d'Assurance celles en vigueur au jour de la Souscription.
      </p>
      <p>
        HomeServe propose aux Utilisateurs du Site de souscrire des Contrats d'Assurance. L'ensemble des informations
        relatives aux Contrats d'Assurance proposés sont accessibles sur le Site pour permettre à l'Utilisateur de
        souscrire un Contrat d'Assurance adapté à ses besoins. Les conditions générales propres à chaque Contrat
        d'Assurance sont accessibles sur le Site et peuvent être conservées sur un support durable. L'Utilisateur peut,
        en outre, solliciter HomeServe pour les recevoir par courrier.{` `}
        <Bold>L’activité de HomeServe relève du contrôle de l’ACPR : 4, place de Budapest 75436 Paris Cedex 09.</Bold>
      </p>
      <LegalMinititle>3-1 Montant des primes des Contrats d’Assurance</LegalMinititle>
      <p>
        Pour chaque Contrat d'Assurance, il est indiqué le montant de la prime, le prix est mentionné en euros toutes
        taxes comprises, sur la base des montants en vigueur au moment de la Souscription. En fonction des différentes
        offres commerciales, une date de validité est mentionnée.
      </p>
      <LegalMinititle>3-2 Disponibilité des Contrats d’Assurance</LegalMinititle>
      <p>
        Tous les Contrats d'Assurance proposés ne couvrent pas l'ensemble du territoire français. L’Utilisateur peut
        saisir son code postal afin de savoir si la situation géographique du domicile à assurer appartient à la zone de
        couverture du Contrat d’Assurance choisi.
      </p>
      <LegalMinititle>3-3 Étapes de la Souscription en ligne d’un Contrat d’Assurance</LegalMinititle>
      <p>
        Seul un Utilisateur donnant son autorisation de prélèvement automatique ou ses coordonnées de carte de paiement
        bancaire peut souscrire en ligne. Un Utilisateur souhaitant régler le montant de la prime selon un autre mode de
        paiement peut téléphoner au service client d’HomeServe ({APP_CONFIG.serviceNumber}).
      </p>
      <p>
        Après avoir parcouru les pages présentant les Contrats d’Assurance sur le Site, l’Utilisateur a la possibilité
        de sélectionner le Contrat d'Assurance correspondant à ses besoins.
      </p>
      <p>
        Déroulé de la commande :<br />
      </p>

      <ul>
        <li>
          <Underline>1ère étape</Underline> :{` `}
          <Bold>« Logement à couvrir »</Bold> : l’Utilisateur renseigne les informations relatives au logement à
          couvrir.
        </li>
        <li>
          <Underline>2ème étape</Underline> :{` `}
          <Bold>« Infos personnelles »</Bold> : l’Utilisateur est invité à saisir ses informations personnelles
          relatives à son identité et à ses coordonnées. Il renseigne notamment son adresse e-mail qui servira
          d’identifiant pour la création de son futur Espace Client, s’il finalise sa commande.
        </li>
        <li>
          <Underline>3ème étape</Underline> : <Bold>« Récapitulatif »</Bold> : l’Utilisateur visualise les données
          renseignées, sa commande (demande de Souscription) et le prix total. Il peut en vérifier les détails et
          modifier les informations saisies en vue de corriger d’éventuelles erreurs ou de modifier sa commande. Pour
          finaliser sa commande avec obligation de paiement, l’Utilisateur doit avoir pris connaissance et accepter le
          document d’information sur le produit d’assurance, les Conditions Juridiques du Site, ainsi que les conditions
          générales relatives au Contrat d’Assurance choisi.
        </li>
        <li>
          <Underline>4ème étape</Underline> : <Bold>« Paiement »</Bold> : l’Utilisateur choisit son mode de paiement,
          paye sa commande en renseignant ses coordonnées bancaires (autorisation de prélèvement automatique ou numéro
          de carte bancaire) et clique sur l’icône « Procéder au paiement » pour confirmer son paiement.
        </li>
      </ul>

      <p>
        A l’issue de ce parcours de Souscription, la page «{` `}
        <Bold>Confirmation de commande</Bold> » informe l'Utilisateur de la bonne prise en compte de sa demande de
        Souscription qui rappelle le type de contrat demandé ainsi que l’adresse e-mail communiquée par l’Utilisateur
        permettant de réceptionner par courrier électronique la confirmation de demande de Souscription. La date de
        Souscription correspond à la date de la demande de Souscription de l’Utilisateur sur le Site.
      </p>
      <p>
        L’Utilisateur peut à ce moment-là définir un mot de passe pour activer son Espace Client. Il pourra également
        définir son mot de passe en cliquant depuis sur le lien prévu à cet effet, reçu par courrier électronique.
      </p>
      <LegalMinititle>3-4 Accusé de réception de la Souscription</LegalMinititle>
      <p>
        HomeServe fera son possible pour adresser au Client un courrier électronique lui accusant réception de sa
        Souscription dans un délai maximum de 24 heures. Cette confirmation électronique retracera les caractéristiques
        essentielles du Contrat d’Assurance souscrit.
        <br />
        Le contenu de cette confirmation de Souscription est archivé par HomeServe. La confirmation est considérée comme
        valant preuve de la commande du Client à la présente transaction et de sa date. La confirmation est distincte du
        courrier postal ou e-mail envoyé postérieurement qui indique les conditions particulières du Contrat
        d'Assurance.
      </p>
      <LegalMinititle>3-5 Droit de renonciation</LegalMinititle>
      <p>
        Le Client dispose d'un droit de renonciation après avoir souscrit, sans avoir à justifier de motifs ni de
        pénalités. Le Client dispose d'un délai de renonciation de 14 jours à compter de la date de réception des
        conditions particulières, celles –ci étant réputées reçues, sauf preuve contraire, 14 jours après la date
        d’effet (date de Souscription). Pour exercer son droit de renonciation, le Client adressera une lettre à
        l'adresse suivante ; HOMESERVE – Service Clients – TSA 82111 – 69303 Lyon Cedex 07 (exemple :{` `}
        <Italic>« Je, soussigné(e) [Prénom Nom], souhaite renoncer au Contrat n°[…] souscrit le […] »</Italic>
        ). Le montant de la prime éventuellement perçu sera alors remboursé dans un délai de 30 jours à compter de la
        réception de la demande de renonciation.
      </p>
      <LegalMinititle>3-6 Archivage du Contrat d’Assurance</LegalMinititle>
      <p>
        Conformément aux dispositions de l'article L.213-1 du Code de la consommation, pour les contrats dont le montant
        de la prime annuelle est supérieur à 120 euros, HomeServe procède à l'archivage du Contrat d’Assurance pendant
        une durée de dix ans à compter de la Souscription et en garantit à tout moment l'accès au Client souscripteur si
        ce dernier en fait la demande.
      </p>
      <LegalSubtitle>Article 4 Espace Client</LegalSubtitle>
      <p>
        La création d’un Espace Client est accessible à l’adresse{` `}
        <ExternalLink href={createSelfCareLink()}>espace-client/homeserve.fr</ExternalLink>
        . La création de l’Espace Client est gratuite et est ouverte pour tout Client ayant souscrit un Contrat
        d’Assurance (par téléphone, courrier ou par l’intermédiaire d’un site internet). Pour créer son Espace Client,
        le Client doit renseigner le formulaire de création et choisir un mot de passe.
        <br />
        Le Client s’engage à compléter le formulaire de bonne foi, en fournissant des informations exactes. Il s’engage
        à ne pas usurper d’identité, ni utiliser un numéro de Client qui ne serait pas le sien. Le Client s’engage à
        maintenir la confidentialité de son mot de passe, afin de lui permettre d’accéder à son Espace Client.
        <br />
        Le Client ne saurait donc rechercher la responsabilité de HomeServe en cas d’agissements sur le Site par un
        tiers en possession de son mot de passe, à charge pour le Client de se retourner contre ce tiers. Le Client
        s’engage à signaler à HomeServe, dans les meilleurs délais après en avoir pris connaissance, toute utilisation
        non autorisée de son mot de passe.
      </p>
      <LegalSubtitle>Article 5 Responsabilités</LegalSubtitle>
      <LegalMinititle>5-1 Responsabilité d’HomeServe</LegalMinititle>
      <p>
        Les contenus de nature rédactionnelle (informations, conseils, …) ne contiennent que de simples informations
        pratiques et ne constituent pas un conseil juridique ou un quelconque engagement de la part de HomeServe. Les
        Utilisateurs doivent s’adresser à un expert pour obtenir un conseil approprié qui soit spécifiquement adapté à
        leur situation. HomeServe ne saurait donc être tenue responsable des conséquences directes ou indirectes de
        l’utilisation de ces informations. HomeServe fera ses meilleurs efforts pour maintenir le Site accessible 7
        jours sur 7 et 24 heures sur 24.
        <br />
        Néanmoins, HomeServe se réserve à tout moment, le droit de modifier, de suspendre ou d’interrompre
        l’accessibilité du Site ou à toute ou partie des contenus et/ou Services que ce soit en raison d’opérations
        techniques (maintenance, migration, mises à jours, pannes ou contraintes liées au fonctionnement des réseaux) ou
        pour toute autre raison, à sa seule discrétion. HomeServe ne pourra être tenue responsable des interruptions et
        modifications du Site et des Services.
        <br />
        En outre, la responsabilité d’HomeServe ne saurait être engagée en cas de force majeure ou de fait indépendant
        de sa volonté, notamment en cas d’interruption du Site ou des Services résultant d’une défaillance du réseau de
        communications ou du fournisseur d’accès à Internet de l’Utilisateur.
      </p>
      <LegalMinititle>5-2 Responsabilité de l’Utilisateur</LegalMinititle>
      <p>
        L’Utilisateur s’engage à utiliser le Site et les Services conformément à la législation et réglementation
        applicable et aux présentes Conditions Juridiques.
      </p>
      <LegalSubtitle>Article 6 Protection des Données Personnelles et cookies</LegalSubtitle>
      <p>
        HomeServe s’engage à respecter la réglementation en vigueur dans les cas où elle collecte et traite les Données
        Personnelles des Utilisateurs du Site. Pour plus d’informations, l’Utilisateur est invité à consulter la
        {` `}
        <Link
          target="_blank"
          rel="noreferrer"
          href="https://www.homeserve.fr/politique-protection-donnees-personnelles?lien_interne=Footer"
        >
          Politique de Données Personnelles
        </Link>
        {` `}
        et la{` `}
        <Link
          target="_blank"
          rel="noreferrer"
          href="https://www.homeserve.fr/politique-des-cookies?lien_interne=Footer"
        >
          Politique des Cookies
        </Link>
        .
      </p>
      <LegalSubtitle>Article 7 Propriété intellectuelle</LegalSubtitle>
      <p>
        Toute reproduction totale ou partielle des marques et logos effectuée à partir des éléments du Site et sans
        autorisation expresse et préalable de HomeServe est prohibée au sens de l'article L. 713-2 du Code de la
        propriété intellectuelle.
        <br />
        L'ensemble des éléments constituant le Site (notamment mais sans limitation les textes, illustrations,
        photographies, images, vidéos, sons, plans, noms, logos, marques, logiciels et bases de données, etc.), les
        signes distinctifs tels que les marques, logos ou autres, et tout logiciel utilisé sur le Site ainsi que le Site
        lui-même sont la propriété exclusive de HomeServe ou sont utilisés par HomeServe avec l'autorisation des
        titulaires de droits et sont protégés au titre de la propriété intellectuelle. Conformément au Code de la
        propriété intellectuelle, il est interdit de reproduire, représenter, modifier, adapter, traduire, extraire,
        toute ou partie du Site et/ou des éléments constituant le Site, sans autorisation préalable et expresse de
        HomeServe.
        <br />
        HomeServe se réserve la possibilité d'engager des poursuites judiciaires à l'encontre de toute personne qui ne
        respecterait pas cette interdiction.
      </p>
      <LegalSubtitle>Article 8 Les liens</LegalSubtitle>
      <p>
        Le Site peut fournir des liens vers d'autres sites. Ces sites sont indépendants du Site. HomeServe n'édite pas
        et ne contrôle pas les sources et les contenus de ces sites internet ou de leurs liens avec d'autres sites.
        <br />
        Les liens vers ces sites ne constituent en aucune manière une approbation ou validation ou adhésion au contenu
        de ces sites. En conséquence, HomeServe dégage toute responsabilité les concernant et ne saurait dès lors être
        tenu pour responsable pour le contenu, les produits, les services, la publicité, les cookies ou autres éléments
        de ces sites ainsi que pour tous les dommages ou pertes, avérés ou allégués, consécutifs ou en relation avec
        l'utilisation de ces informations, services ou données disponibles sur ces sites.
        <br />
        Les stipulations du présent article 8 ne s’appliquent pas aux sites internet des sociétés du Groupe HomeServe.
      </p>
      <LegalSubtitle>Article 9 Service Client et Réclamations</LegalSubtitle>
      <p>
        En cas de réclamation, le Client peut contacter le Service Clients de HomeServe au {APP_CONFIG.serviceNumber}.
        Si la réponse apportée n’est pas satisfaisante, le Client peut écrire à HomeServe en utilisant le formulaire de
        contact accessible sur le Site ou par lettre simple à : HomeServe - Service Réclamations - TSA 82111 - 69303
        Lyon Cedex 07. Si, après avoir épuisé les voies de recours auprès des services de HomeServe, la réponse apportée
        n’est toujours pas satisfaisante, le Client a la possibilité de saisir le médiateur en adressant sa demande par
        courrier postal à : La médiation de l’Assurance – TSA 50110 – 75441 Paris Cedex 09 ou par voie électronique :
        www.mediation-assurance.org. Par ailleurs, la Commission Européenne met à disposition une plateforme de
        résolution des différends, en ligne, accessible à l’adresse :{` `}
        <ExternalLink href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</ExternalLink>.
      </p>
      <LegalSubtitle>Article 10 Divers</LegalSubtitle>
      <p>
        Les présentes CGU/CGV sont rédigées en langue française et sont soumises au droit français. Si une partie des
        CGU/CGV devait s'avérer illégale, invalide ou inapplicable, pour quelque raison que ce soit, les dispositions en
        question seraient réputées non écrites, sans remettre en cause la validité des autres dispositions qui
        continueront à s'appliquer entre l'Utilisateur et HomeServe. HomeServe se réserve le droit de sous-traiter et ou
        de céder à des tiers, ses droits et obligations au titre des présentes CGU/CGV.
      </p>
      <LegalLink
        target="_blank"
        rel="noreferrer"
        href="https://www.homeserve.fr/politique-protection-donnees-personnelles?lien_interne=Footer"
      >
        <LegalTitle>II/ POLITIQUE DE PROTECTION DES DONNEES PERSONNELLES</LegalTitle>
      </LegalLink>

      <LegalLink
        target="_blank"
        rel="noreferrer"
        href="https://www.homeserve.fr/politique-des-cookies?lien_interne=Footer"
      >
        <LegalTitle>III/ POLITIQUE DES COOKIES</LegalTitle>
      </LegalLink>
    </Block>
  )
}
